import "../css/Home.css";
import api from "../authentication/api";
import ErrorHandler from "./Error";
import { useState, useRef } from "react";
import Loading from "./Loading";

function Home() {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const username = useRef<any>(null);

  if (username.current === null)
    username.current = localStorage.getItem("username");

  async function GoToNextActivity() {
    setLoading(true);
    const response = await api.get("/activities/next");
    if (response.status !== 200) {
      console.log(response.message);
      setError(true);
      return;
    }
    if (response.theme)
      // If there is a next Activity
      return window.location.replace(`/activity/${response.theme}`);
    else setError(true);
  }

  if (error) return <ErrorHandler />;

  if (loading) return <Loading />;

  return (
    <div className="Home">
      <div className="container">
        <h1>Welcome to english4learn</h1>
        <br />
        <p>
          Welcome to the English class <strong>{username.current}</strong>
        </p>

        <div className="startActivity">
          <button
            type="button"
            onClick={GoToNextActivity}
            className="btn btn-success btn-lg"
          >
            Go to next activity
          </button>

          <hr />
          <p>Or</p>

          <a href="/select">Select Course</a>
        </div>
      </div>
    </div>
  );
}

export default Home;
